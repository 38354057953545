import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm, UntypedFormControl, FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { defaultNgxLoadingConfig } from '../../config/index';
import { ErrorStateMatcher } from '@angular/material/core';
import { User } from '../../models/user';
import { Balance } from '../../models/balance';
import { History } from './../../models/history';
import { IFile } from './../../models/file';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Utils } from '../../utils/index';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { MatomoService } from '../../services/matomo.service';
import { UrlService } from './../../services/url.service';
import { ConfirmDialogComponent } from './../../components/confirm-dialog/confirm-dialog.component';
import { CatalogueService } from '../../services/catalogue.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class CustomErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        const invalidCtrl = !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
        let customError = false;
        if (control === form.form.get('email')) {
            customError = control.parent.hasError('emailNotConfirmed');
        }
        else if (control === form.form.get('password')) {
            customError = control.parent.hasError('passwordNotConfirmed');
        }
        const invalidParent = !!(control && control.parent && customError && control.parent.dirty);
        return (invalidCtrl || invalidParent);
    }
}

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
    public form: UntypedFormGroup;
    public matcher = new CustomErrorStateMatcher();
    public user: User;
    public editing: boolean = false;
    public avatar: IFile;
    public balance: Balance;
    public history: History;
    public savingText: string = "Sending...";
    public okText: string = "OK";
    public loader = {
        config: defaultNgxLoadingConfig,
        loading: false
    };
    public userSubscription: Subscription;
    public showProgress: boolean = true;
    public numberEmptyProperties = 0;
    public maxBirthday: moment.Moment = moment();
    public showTicketSection: string = null;
    public showMoreTicketsR: string = 'none';
    public authCodeDialogRef: MatDialogRef<ConfirmDialogComponent>;
    public minRechargeCost = 30;

    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        public translate: TranslateService,
        private router: Router,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private userService: UserService,
        private urlService: UrlService,
        public googleAnalyticsService: GoogleAnalyticsService,
        private matomoService: MatomoService,
        private catalogueService: CatalogueService,
    ) {
        let params = {
            is_active: 1,
            sort: 'cost|asc', //Este orden es importante para posteriormente elegir el primer elemento y será el del costo más chico
        }
        this.translate.get([this.savingText, this.okText], {}).subscribe((trans) => {
            this.savingText = trans[this.savingText];
            this.okText = trans[this.okText];
        });
        this.user = this.authService.getCurrentUser();
        catalogueService.getPhoneRechargeProducts(params).then(products => {
            if (products.length > 0) {
                this.minRechargeCost = products[0].cost;
            }
        });
        this.clearErrors();
    }

    private clearErrors() {
        //
    }

    ngOnInit() {
        this.loader.loading = true;
        this.userSubscription = this.userService.authUserUpdated$.subscribe(user => {
            this.user = user;
            this.countEmptyProperties();
        });
        this.userService.getMyBalance().then(balance => {
            this.balance = balance;
        }).catch(error => { }).then(() => {
            this.loader.loading = false;
        });
        this.userService.getMyHistory().then(history => {
            this.history = history;
        }).catch(error => { }).then(() => {
            this.loader.loading = false;
        });

        this.form = this.fb.group({
            first_name: [this.user.firstName, Validators.required],
            last_name: [this.user.lastName, Validators.required],
            phone: [this.user.phone, Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')],
            email: [this.user.email],
            gender: [this.user.gender],
            birthday: [this.user.birthday],
            job: [this.user.job],
            password: ['********', Validators.minLength(8)],
            password_confirmation: [''],
            authcode: [''],
        }, {
            validators: [this.passwordConfirmedValidator]
        });
        this.form.get('first_name').disable();
        this.form.get('last_name').disable();
        this.form.get('phone').disable();
        this.form.get('email').disable();
        this.form.get('password').disable();
        this.form.get('password_confirmation').disable();
        this.form.get('gender').disable();
        this.form.get('birthday').disable();
        this.form.get('job').disable();
        this.avatar = {
            name: null,
            contents: this.user.avatar
        }
        this.countEmptyProperties();
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    private passwordConfirmedValidator(form: UntypedFormGroup) {
        let pass = form.get('password');
        const matches = pass.value === form.get('password_confirmation').value;
        return !matches && pass.dirty ? { passwordNotConfirmed: true } : null;
    }

    public showMoreTicket() {
        this.showMoreTicketsR = 'block';
    }
    public showTickets(current) {
        this.showTicketSection = current;
        this.showMoreTicketsR = 'none';
    }

    public requestAuthCode() {
        const ref = Utils.alert(this.dialog, {
            data: {
                message: 'Solicitanto código de autorización...',
                showAcceptButton: false,
                type: 'loading',
            },
            disableClose: true,
        });
        this.userService.requestAuthorizationCode().then((response) => {
            // Mostrar diálogo para ingresar el código
            this.showAuthCodeDialog(response.message);
        }).catch((response: HttpErrorResponse) => {
            if (response.status === 422) {
                // Mostrar diálogo para ingresar el código
                this.showAuthCodeDialog(response.error.message);
            }
        }).then(() => {
            ref.close();
        });
    }

    public showAuthCodeDialog(message: string) {
        this.authCodeDialogRef = Utils.confirm(this.dialog, {
            data: {
                title: "Ingresar código de autorización",
                message: `${message}\nIngresa el código aquí.`,
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showInput: true,
                inputLabel: "Código",
                callback: (val) => {
                    this.router.navigate(['transfer'], {queryParams: {'authcode': val}});
                },
                inputValidator: (val) => this.validateAuthCode(val)
            },
            disableClose: true,
        });
    }

    public async validateAuthCode(authCode: string): Promise<string> {
        // Enviar petición al api para validar el código
        try {
            await this.userService.checkAuthorizationCode(authCode);
            return null;
        } catch (e) {
            if (e instanceof HttpErrorResponse && e.status === 422) {
                return e.error.message;
            }
        }
        return "El código no es válido";
    }

    public enableEditProfile(): void {
        this.showTicketSection = null;
        this.showProgress = false;
        this.editing = true;
        this.form.get('first_name').enable();
        this.form.get('last_name').enable();
        if (!this.user.phone) {
            this.form.get('phone').enable();
        }
        this.form.get('password').enable();
        this.form.get('password_confirmation').enable();
        this.form.get('gender').enable();
        this.form.get('birthday').enable();
        this.form.get('job').enable();
    }

    public cancelEditing(): void {
        this.showTicketSection = null;
        this.showProgress = true;
        this.editing = false;
        this.form.get('first_name').disable();
        this.form.get('last_name').disable();
        this.form.get('phone').disable();
        this.form.get('password').disable();
        this.form.get('password_confirmation').disable();
        this.form.get('gender').disable();
        this.form.get('birthday').disable();
        this.form.get('job').disable();
    }

    public editProfile(): void {
        if (!this.editing) {
            return;
        }
        this.clearErrors();
        if (!this.form.valid) {
            return;
        }
        let ref = Utils.alert(this.dialog, {
            data: {
                message: this.savingText + '...',
                showAcceptButton: false,
                type: 'loading',
            },
            disableClose: true,
        });
        let data = {...this.form.value};
        if (this.avatar.name !== null && this.avatar.contents) {
            data.avatar = this.avatar;
        }
        if (data.password === '********') {
            delete data.password;
            delete data.password_confirmation;
        }
        if (data.birthday) {
            data.birthday = data.birthday.format('DD/MM/YYYY');
        }
        this.userService.updateMe(data).then((response) => {
            this.googleAnalyticsService.eventEmitter("update_profile", "engagement");
            this.matomoService.trackEvent('Profile', 'update');
            setTimeout(() => {
                Utils.alert(this.dialog, {
                    data: {
                        message: response.message,
                        type: 'success'
                    },
                });
            }, 300);
            this.showProgress = true;
        }).catch((response: HttpErrorResponse) => {
            if (response.status === 422) {
                this.snackBar.open(response.error.message, this.okText, {
                    duration: 8000
                });
                if (response.error.errors) {
                    Object.keys(response.error.errors).forEach(key => {
                        const formControl = this.form.get(key);
                        if (formControl) {
                            formControl.setErrors({
                                serverError: response.error.errors[key][0]
                            });
                        }
                    });
                }
            }
        }).then(() => {
            ref.close();
        });
    }

    public onImageCropped(data: IFile) {
        this.avatar = data;
    }

    public requestTransfer() {
        if (this.balance.available < 25) {
            Utils.alert(this.dialog, {
                data: {
                    message: "Necesitas acumular al menos MX $25 para solicitar una transferencia.<br>¡Sigue subiendo tus tickets!",
                    type: 'info',
                }
            });
        } else {
            this.requestAuthCode();
        }
    }

    private countEmptyProperties() {
        let propertiesToCount = ['phone', 'gender', 'birthday', 'job'];
        if (!this.user) {
            return;
        }
        this.numberEmptyProperties = Object.keys(this.user).filter(key => propertiesToCount.indexOf(key) >= 0 && !this.user[key]).length;
        if (!this.avatar || !this.avatar.contents) {
            this.numberEmptyProperties++;
        }
    }

    public donations() {
        if (this.balance.available <= 0) {
            Utils.alert(this.dialog, {
                data: {
                    message: "No tienes saldo para donar.<br>¡Sigue subiendo tus tickets!",
                    type: 'info',
                },
            });
        } else {
            this.router.navigate(['donations']);
        }
    }

    public verifyPhone() {
        this.router.navigate(['verify-phone']);
    }

    public rechargePhone(provider: string = 'att') {
        if (this.balance.available < this.minRechargeCost) {
            Utils.alert(this.dialog, {
                data: {
                    message: "Necesitas acumular al menos MX $" + this.minRechargeCost + " para solicitar una recarga.<br>¡Sigue subiendo tus tickets!",
                    type: 'info',
                }
            });
        } else {
            this.router.navigate(['recarga-tu-cel'], { queryParams: { provider } });
        }
    }
}
