import { PhoneRechargeService } from './../../services/phone-recharge.service';
import { CatalogueService } from './../../services/catalogue.service';
import { PhoneRechargeProduct } from './../../models/phone-recharge-product';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Balance } from '../../models/balance';
import { ErrorStateMatcher } from '@angular/material/core';
import { Utils } from '../../utils';
import { HttpErrorResponse } from '@angular/common/http';
import { IRequestPhoneRecharge, PhoneRecharge } from './../../models/phone-recharge';
import { environment } from './../../../environments/environment';
import { TermsConditionsComponent } from './../../pages/terms-conditions/terms-conditions.component';
import { ActivatedRoute, Router } from '@angular/router';

export class CustomErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        const invalidCtrl = !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
        let customError = false;
        if (control === form.form.get('phone')) {
            customError = control.parent.hasError('phoneNotConfirmed') || control.parent.hasError('phoneLengthInvalid');
        }
        const invalidParent = !!(control && control.parent && customError && control.parent.dirty);
        return (invalidCtrl || invalidParent);
    }
}

type PhoneRechargeForm = {
    [Property in keyof IRequestPhoneRecharge]-?: FormControl<IRequestPhoneRecharge[Property] | null>;
}

@Component({
    selector: 'app-phone-recharges',
    templateUrl: './phone-recharges.component.html',
    styleUrls: ['./phone-recharges.component.scss']
})
export class PhoneRechargesComponent implements OnInit {
    public balance: Balance;
    public form: FormGroup<PhoneRechargeForm>;
    public matcher = new CustomErrorStateMatcher();
    public phoneRechargeProducts: PhoneRechargeProduct[];
    public phoneRechargeRequested: boolean = false;
    public phoneRecharge: PhoneRecharge;
    public phoneRechargeError: boolean = false;
    public termsUrl: string;
    public provider: string = 'att';

    constructor(
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
        private userService: UserService,
        private catalogueService: CatalogueService,
        private phoneRechargeService: PhoneRechargeService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.provider = this.activatedRoute.snapshot.queryParams['provider'] ?? 'att';
        if (!['att', 'unefon'].includes(this.provider)) {
            this.router.navigate(['not-found']);
        }
        this.fetchBalance();
        this.catalogueService.getPhoneRechargeProducts({ is_active: 1, carrier_slug: this.provider })
            .then(response => this.phoneRechargeProducts = response)
            .catch(error => { });
        this.form = new FormGroup<PhoneRechargeForm>({
            phone_recharge_product_id: new FormControl(null, { nonNullable: true, validators: [Validators.required] }),
            phone: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
            phone_confirmation: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
            accept_terms: new FormControl(false, { nonNullable: true, validators: [Validators.requiredTrue]}),
        }, {
            validators: [this.phoneConfirmedValidator, this.phoneLengthValidator]
        });
        this.termsUrl = `${environment.websiteUrl}/terminos-y-condiciones`;
    }

    private fetchBalance() {
        this.userService.getMyBalance().then(balance => {
            this.balance = balance;
        })
        .catch(error => { });
    }

    private phoneConfirmedValidator(form: FormGroup) {
        const matches = form.get('phone').value === form.get('phone_confirmation').value;
        return !matches ? { phoneNotConfirmed: true } : null;
    }

    private phoneLengthValidator(form: FormGroup) {
        const value = form.get('phone').value;
        return value.length >= 1 && value.length !== 10 ? { phoneLengthInvalid: true } : null;
    }

    public onSubmit() {
        if (!this.form.valid) {
            return;
        }
        const data = this.form.value;
        const phoneRechargeProduct = this.phoneRechargeProducts.find(
            p => p.id == data.phone_recharge_product_id
        );
        if (phoneRechargeProduct.cost > this.balance.available) {
            Utils.alert(this.dialog, {
                data: {
                    message: "No cuentas con saldo suficiente para realizar la recarga " +
                        "solicitada.<br>¡Sigue subiendo tus tickets!",
                    type: 'info'
                }
            });
            return;
        }
        this.phoneRechargeRequested = true;
        Utils.confirm(this.dialog, {
            disableClose: true,
            data: {
                title: "Confirmar recarga",
                message: "¿Estás seguro que quieres continuar?<br>No hay devoluciones.",
                confirmButtonText: "Sí",
                cancelButtonText: "Cancelar",
                callback: () => {
                    this.requestPhoneRecharge(data);
                },
                onCancel: () => {
                    this.phoneRechargeRequested = false;
                    this.router.navigate(['home']);
                },
            }
        });
    }

    public requestPhoneRecharge(data: Partial<IRequestPhoneRecharge>) {
        const ref = Utils.alert(this.dialog, {
            data: {
                message: 'Solicitando recarga, por favor no cierres la página.',
                showAcceptButton: false,
                type: 'loading',
            },
            disableClose: true,
        });
        this.phoneRechargeService.requestPhoneRecharge(data).then((response) => {
            this.fetchBalance();
            this.phoneRecharge = response;
        }).catch((response: HttpErrorResponse) => {
            if (response.status === 422) {
                this.phoneRechargeError = ['phone_recharge_error', 'phone_recharge_rejected'].indexOf(response.error.code) !== -1;
                this.snackBar.open(response.error.message, "Aceptar", {
                    duration: 8000,
                });
                if (response.error.errors) {
                    Object.keys(response.error.errors).forEach(key => {
                        const formControl = this.form.get(key);
                        if (formControl) {
                            formControl.setErrors({
                                serverError: response.error.errors[key][0]
                            });
                        }
                    });
                }
            }
        }).finally(() => {
            ref.close();
            this.phoneRechargeRequested = false;
        });
    }

    public goToProfile() {
        this.router.navigate(['profile']);
    }

    public showTerms() {
        this.dialog.open(TermsConditionsComponent, {
            data: {}
        });
    }
}
