<h1 mat-dialog-title>{{ 'Terms and conditions' | translate }}</h1>
<div mat-dialog-content>
    <h4><strong>MONEO Términos de Uso</strong></h4>
    <p><strong>https://www.moneo.cash/</strong></p>
    <p><strong>Fecha de última actualización: 06 de febrero del 2025</strong></p>

    <p><strong>Yulah Ventures, SA. de CV. ("Moneo")</strong>, con domicilio en Georgia 112-301, Colonia Nápoles, CP 03810, Delegación Benito Juárez, Ciudad de México, con clave de Registro Federal de Contribuyentes YVE130125PY7  ("Moneo", "nosotros", y términos de significado similar) te proporciona esta solución (este sitio web, aplicación móvil y cualquier otro programa o software proporcionado por Moneo, para su uso con la solución o de otro tipo, colectivamente, el "Sitio") y quedas sujeto a estos términos de uso (estos "Términos"). En estos Términos se describe a los usuarios del Sitio, registrados o no, como "Usuarios".</p>
    <p>Por favor lee estos Términos cuidadosamente antes de usar el Sitio. Al acceder, usar o navegar por el Sitio, estás obligado a aceptar legalmente estos Términos. Si no estás de acuerdo con estos Términos en su totalidad, no podrás utilizar el Sitio.</p>
    <p>Los diversos servicios que ofrecemos a través del Sitio (colectivamente, los "Servicios") son para uso exclusivo del Usuario. El Usuario no puede revender, arrendar o proveer de otra manera a ninguna otra persona el Servicio.</p>

    <h4><strong>I. Elegibilidad</strong></h4>
    <p><strong>DEBES TENER 18 AÑOS DE EDAD O MÁS PARA USAR ESTE SITIO. ES UNA VIOLACIÓN EN VIRTUD DE LA LEY PARA CUALQUIER PERSONA MENOR DE 18 AÑOS QUE PRESENTE PRUEBAS POR ESCRITO FALSIFICANDO QUE ES MAYOR DE EDAD, FRAUDULENTAS O NO CON EL FIN DE TRATAR DE COMPRAR O CANJEAR ALGUNA OFERTA RELACIONADA CON CUALQUIER BEBIDA ALCOHÓLICA.</strong></p>
    <p>CUALQUIER PERSONA MENOR DE 18 AÑOS DE EDAD NO PUEDE USAR ESTE SITIO.</p>
    <p>EL SERVICIO SÓLO ESTÁ DISPONIBLE PARA PERSONAS RESIDENTES EN MÉXICO.</p>

    <h4><strong>II. Política de Privacidad</strong></h4>
    <p>Consulte el aviso de privacidad de Moneo, disponible en <a href="https://www.moneo.cash/aviso-de-privacidad" target="_blank">https://www.moneo.cash/aviso-de-privacidad</a> (el "Aviso de Privacidad") para obtener información sobre cómo Moneo recopila, usa y divulga información personal de los Usuarios del Sitio. Al usar el Sitio aceptas nuestro uso, recolección y divulgación de información personal de acuerdo con el Aviso de Privacidad.</p>

    <h4><strong>III. Modificación de Términos</strong></h4>
    <p>Moneo puede cambiar o modificar cualquiera de los términos y condiciones contenidos en este documento de Términos, o cualquier política o directriz del Sitio, en cualquier momento y a su discreción. A menos que se especifique lo contrario, cualquier cambio o modificación será efectiva inmediatamente después de la publicación de las revisiones en el sitio, y el uso continuo del Sitio después de dicha hora se constituirá su aceptación de tales cambios o modificaciones. Tú debes revisar de vez en cuando los Términos y las políticas y los documentos incorporados en ellos para entender los términos y condiciones que se aplican al uso del Sitio. Los Términos siempre mostrarán la fecha de "última actualización" en la parte superior. Si no estás de acuerdo con los Términos modificados, deberás de dejar de usar el Sitio. Si tienes alguna pregunta sobre los Términos, envíanos un correo electrónico a la siguiente dirección de contacto: soporte&#64;moneo.mx.</p>

    <h4><strong>IV. Datos de Registro; Seguridad de la Cuenta</strong></h4>
    <p>Si te registras para obtener una cuenta en el Sitio, estás aceptando:</p>
    <ol>
        <li>Proporcionar información exacta, actual y completa, tal como puede ser solicitada por cualquier formulario de registro en el Sitio ("Datos de Registro");</li>
        <li>Mantener la seguridad de su contraseña;</li>
        <li>Mantener y actualizar con rapidez los Datos de Registro y cualquier otra información que tú le proporciones al Sitio, y mantenerla de manera exacta, actual y completa;</li>
        <li>Aceptar todos los riesgos de acceso no autorizado a los Datos de Registro y cualquier otra información que usted proporcione al Sitio.</li>
        <li>Utilizar este sitio con fines personales y no con fines comerciales.</li>
        <li>No tener más de una cuenta y no vender, comerciar o transferir esa cuenta a ninguna otra persona, ni acceder a Moneo por ningún otro medio que no sea a través de la interfaz proporcionada públicamente.</li>
    </ol>
    <p>Tú eres responsable de toda la actividad en tu cuenta del Sitio. En caso de que se te haya olvidado la información de acceso y que quieras recuperarla, se te dirigirá a una página de recuperación de contraseñas en la que se te pedirá la dirección de correo electrónico asociada a la cuenta. Si la dirección de correo electrónico es válida, se enviará una clave a tu cuenta de correo electrónico en la que puedes hacer clic para restaurar o restablecer la contraseña de la cuenta.</p>
    <p>Tú aceptas que eres el único responsable de cualquier incumplimiento de tus obligaciones bajo los Términos y por las consecuencias de tal incumplimiento, incluyendo cualquier pérdida o daño que Moneo pueda sufrir. Si te das cuenta de cualquier uso no autorizado de tu cuenta, aceptas notificarnos inmediatamente a soporte&#64;moneo.mx.</p>

    <h4><strong>V. Los Servicios</strong></h4>

    <h4><strong>V.1 Recompensas</strong></h4>
    <p>El propósito de Moneo es ayudarte a ahorrar dinero al darte recompensas por ciertas compras y otras actividades que cumplen con nuestras condiciones.</p>
    <p>A través de los Servicios, Moneo puede ofrecerte ofertas de empresas terceras (cada una, una "Oferta").  Cuando redimes una Oferta a través del Sitio, acumularás recompensas en tu cuenta Moneo ("Recompensas").</p>
    <p>Solo puedes redimir Recompensas a través del Sitio. No las puedes redimir por email, teléfono ni otros medios de comunicación y / o envío.</p>
    <p>Cada Oferta puede tener sus propios términos y condiciones, los cuales necesitas respetar.  Moneo puede, en cualquier momento y sin previo aviso, rescindir una Oferta o quitarla de la lista de Ofertas disponibles.</p>
    <p>No se aceptan los recibos de comercios para pagos de servicios, pagos de impuestos, depósitos bancarios o depósitos referenciados.</p>
    <p>Si no podemos obtener cierta información clave de la compra asociada con la Oferta, es posible que no te demos las Recompensas. Por ejemplo, si no recibimos una imagen clara de tu recibo de compra o si el recibo falta información, es posible que no te entreguemos las Recompensas en tu cuenta Moneo.</p>
    <p>Asimismo, Moneo reserva el derecho de i) determinar si eres elegible para redimir una Oferta; ii) determinar si has ganado las Recompensas; iii) ajustar tu total de Recompensas para que refleje de manera justa las Recompensas que has ganado; iv) limitar el cálculo de recompensas a los primeros MXN $2,000 (dos mil pesos 00/100 M.N.) de una transacción, es decir que el monto máximo aplicable para redimir Recompensas es de MXN $2,000.00 (dos mil pesos 00/100 M.N.) pesos (por ticket de compra y usuario); v) limitar el número de Ofertas de un comercio que se puede redimir por día a 1 (uno). Por ejemplo, Moneo puede limitar el número de Ofertas de un comercio como Walmart o La Europea a 1 por día. Si el Usuario sube más recibos que el límite permite, no serán aceptados.</p>
    <p>Moneo puede, en cualquier momento y bajo su propia discreción, restar de tu cuenta Moneo cualquier Recompensas que Moneo determina que no has ganado o que has ganado de una manera que viola los Términos.</p>
    <p>Antes de ser dispersadas, las Recompensas no tienen ningún valor monetario y no puedes intercambiar o canjearlas para obtener dinero u otras formas de valor, salvo de la manera que se explica en la Sección V.2. y la Sección V.3.</p>
    <p>Si cancelas tu compra, recibes otra promoción relacionada con la compra, recibes un reembolso de dinero relacionado con la compra, o cualquier otra acción similar, ésta será razón para negar entregarte las Recompensas. Además, si ya te hemos dado las Recompensas, se restará el monto en tu cuenta Moneo por el monto que te dimos.</p>
    <p>Entiendes y aceptas que las Ofertas y Recompensas están ofrecidos por Moneo, no por los socios y proveedores de Moneo. Al aceptar estos Términos, indicas que nunca contactarás a ninguna empresa tercera para solicitar la redención de una Oferta o tratar de conseguir una Recompensa.</p>

    <h4><strong>V.2 Redención de Recompensas</strong></h4>
    <p>Una vez que hayas acumulado el monto de MXN $25 (veinticinco pesos 00/100 M.N.) de Recompensas y los veas reflejados en tu cuenta Moneo, podrás solicitar que Moneo disperse las Recompensas por un medio indicado en el Sitio. Es decir, no se podrán redimir Recompensas hasta en tanto el monto acumulado del Usuario en la cuenta Moneo  sea de por lo menos $25 MXN (veinticinco pesos 00/100 M.N.).</p>
    <p>No puedes redimir o canjear las Recompensas ni solicitar su dispersión hasta que el monto aparezca en tu cuenta Moneo.</p>
    <p>Una vez solicitada la dispersión de las Recompensas, Moneo hará su mejor esfuerzo para transferir los fondos dentro de los siguientes 5 (cinco) días hábiles siguientes a la solicitud a una cuenta bancaria aperturada con una institución financiera mexicana autorizada conforme a la legislación vigente.</p>
    <p>SOLO SE PODRÁN HACER TRANSFERENCIAS A UNA CUENTA BANCARIA EN MÉXICO. ASIMISMO EL TITULAR DE LA CUENTA MONEO Y EL TITULAR DE LA CUENTA BANCARIA DONDE EL USUARIO RECIBIRÁ LA TRANSFERENCIA DE RECURSOS EN VIRTUD DE LAS RECOMPENSAS REDIMIDAS DEBERÁN SER PROPIEDAD DEL MISMO USUARIO. ES DECIR EL NOMBRE DE LA CUENTA MONEO Y EL TITULAR DE LA CUENTA BANCARIA DEBERÁN SER FORZOSAMENTE LA MISMA PERSONA.</p>
    <p>El equipo de Moneo validará que el titular de la cuenta bancaria proporcionada por ti como Usuario y el nombre del Usuario de la cuenta Moneo coincidan.</p>
    <p>Tú, como Usuario, no podrás realizar una transferencia o depósito del importe de las Recompensas a cuentas bancarias de terceros. No podrás realizar transacciones con terceros de ningún tipo a través del Sitio de Moneo. Sólo podrás redimir los importes de las Recompensas obtenidas como resultado de ofertas de Moneo con las empresas afiliadas al programa de Recompensas de Moneo.</p>

  <h4><strong>V.3 Recargas de tiempo aire de telefonía móvil</strong></h4>
  <p>Aunado a lo señalado en la Sección V.2 anterior, el Usuario podrá intercambiar las Recompensas
    por recargas de tiempo aire de telefonía móvil del operador AT&T México (las “Recargas”). Para que
    el Usuario pueda adquirir las Recargas deberá tener en su cuenta Moneo al menos un saldo
    equivalente a MXN ${{ minRechargeCost }} ({{ amounts[minRechargeCost] }} pesos 00/100 M.N.) de Recompensas.</p>
  <p>Actualmente, el Usuario solo podrá adquirir Recargas del operador AT&T México. Las Recargas son
    procesadas por Moneo a través de MTCENTER, S.A. de C.V.</p>
  <p>Para adquirir las Recargas, el Usuario deberá ingresar a través del Sitio a su cuenta Moneo y
    seleccionar la opción de Recargas AT&T en su página de perfil.</p>
  <p>El Usuario en el apartado Recargas AT&T deberá proporcionar a Moneo la información necesaria
    para realizar la Recarga como el operador de telefonía, el número de celular del Usuario y el monto
    deseado de la Recarga.</p>
  <p>Si el Usuario cuenta con el saldo mínimo para realizar la Recarga, y la información proporcionada
    por el Usuario es válida, entonces Moneo procesará la solicitud del Usuario.</p>
  <p>Una vez procesada la Recarga, Moneo restará el monto de la Recarga solicitada por el Usuario del
    saldo de Recompensas del Usuario, por lo cual el saldo de la cuenta Moneo del Usuario disminuirá
    por un monto equivalente al importe de la Recarga.</p>
  <p>Moneo enviará un comprobante de la transacción al Usuario, señalando la confirmación de la
    Recarga realizada y el monto que fue descontado del saldo de la cuenta Moneo del Usuario.</p>
  <p>El Usuario solo podrá solicitar Recargas a través de la redención de Recompensas a través del
    Sitio. No las podrá redimir por e-mail, teléfono, ni otros medios de comunicación.</p>

    <h4><strong>VI. Datos de Ubicación y Segmentación de Ofertas</strong></h4>
    <p>No todos los Usuarios recibirán todas las Ofertas.  Las Ofertas disponibles dependen de varios factores incluyendo pero no limitado a la ubicación geográfica, edad e historial de compras del Usuario. Reservamos el derecho de utilizar algoritmos para presentar al Usuario las Ofertas más relevantes.</p>
    <p>Moneo utiliza información de tu teléfono para poder presentarte las Ofertas más relevantes. Puedes, en cualquier momento, optar por no compartir dicha información con el entendido de que no podremos garantizar que las Ofertas disponibles sean relevantes para ti.</p>
    <p>Consulte el Aviso de Privacidad de Moneo, disponible en <a href="https://www.moneo.cash/aviso-de-privacidad" target="_blank">https://www.moneo.cash/aviso-de-privacidad</a> para obtener información sobre cómo Moneo recopila, usa y divulga información personal de los Usuarios del Sitio.</p>

    <h4><strong>VII. Impuestos</strong></h4>
    <p>Aceptas que eres el responsable para cumplir con cualquier obligación fiscal relacionada con el uso de los Servicios.</p>

    <h4><strong>VIII. Obligaciones de los Usuarios y Prohibiciones Expresas</strong></h4>
    <p>Los servicios del Sitio y de Moneo son propiedad de Moneo y están protegidos por leyes de propiedad intelectual y otras leyes, incluyendo marcas registradas y derechos de autor. Moneo se reserva el derecho de cancelar tu registro con o sin causa en cualquier momento.</p>
    <p>El uso de los servicios del Sitio y Moneo está sujeto a ti siguiendo todas los Términos, así como todas las leyes y regulaciones locales, estatales y federales aplicables.</p>
    <p>Al utilizar el Sitio, tú aceptas:</p>
    <ol>
        <li>Cumplir con las leyes y regulaciones estatales y federales locales;</li>
        <li>No usar el Sitio o los servicios de Moneo si eres menor de 18 años;</li>
        <li>No mantener más de una (1) cuenta por persona;</li>
        <li>No acceder al Sitio o a los servicios de Moneo utilizando una cuenta de afiliación de terceros;</li>
        <li>No utilizar el Sitio con fines ilícitos;</li>
        <li>No cometer ningún acto de infracción en el Sitio;</li>
        <li>No utilizar el Sitio para realizar actividades comerciales;</li>
        <li>No copiar ningún contenido para re-publicación en forma impresa o en línea;</li>
        <li>No crear interrupciones o interferir con el uso y disfrute del Sitio por otra persona;</li>
        <li>No cargar ni transmitir virus u otros archivos dañinos que interrumpan o violen la seguridad del Sitio o de cualquier servicio de Moneo.</li>
        <li>No entregar a Moneo información de compra y / o recibos de compra falsa, errónea, fabricada, modificada o ilegítima de otra forma, con el fin de redimir una Oferta.</li>
        <li>No subir recibos de otras personas y no redimir o tratar de redimir Ofertas para productos y servicios que tú no has comprado, o que has comprado y después devuelto al comercio.</li>
        <li>No subir a través del Sitio recibos que ya se han subido por ti u otro usuario, con el fin de redimir una Oferta ligada a una compra que no has realizado.</li>
        <li>No subir recibos de compras realizadas hace más de siete (7) días de la fecha de subida del recibo.</li>
        <li>No pasar a otros Usuarios de Moneo recibos que tú ya has subido.</li>
    </ol>

    <h4><strong>IX. Contenido del Usuario</strong></h4>
    <p>Los Usuarios otorgan a favor de Moneo una autorización de uso gratuita, no exclusiva, perpetua, transferible e irrevocable sobre los comentarios, imágenes (incluyendo del rostro), nombre del usuario, opiniones, sugerencias, ideas, preguntas o cualquier otro contenido del Usuario ingresado al Sitio para utilizar, reproducir, modificar, adaptar, publicar, traducir o generar obras derivadas de dicho contenido del Usuario, en forma total o parcial y por cualquier medio. Lo anterior, sujeto a lo establecido en el Aviso de Privacidad.</p>

    <h4><strong>X. Contratación y Notificación Electrónica</strong></h4>
    <p>Tu acto de afirmación de uso de este Sitio y/o registro para el Sitio constituye tu firma electrónica a estos Términos y tu consentimiento para celebrar acuerdos con nosotros vía electrónica.</p>

    <h4><strong>XI. Enlaces a Terceros</strong></h4>

    <h4><strong>XI.1 Sitios</strong></h4>
    <p>El Sitio puede contener enlaces a sitios web de terceros ("Sitios de Terceros") y contenido de terceros ("Contenido de Terceros") como un servicio para los interesados en esta información.</p>
    <p>Puedes utilizar enlaces a Sitios de Terceros y cualquier Contenido de Terceros o servicio proporcionado por tu propia cuenta y riesgo. Moneo no controla ni tiene ningún control sobre, ni hace ninguna reclamación o representación con respecto a, Contenido de Terceros o Sitios de Terceros.</p>
    <p>Moneo provee estos enlaces sólo como una conveniencia, y un enlace a un Sitio de Terceros o Contenido de Terceros no implica el respaldo, adopción o patrocinio de Moneo o afiliación con dicho Sitio de Terceros o Contenido de Terceros. Moneo no acepta responsabilidad alguna por revisar los cambios o actualizaciones o la calidad, el contenido, las políticas, la naturaleza o la confiabilidad de los Contenidos de Terceros, los Sitios de Terceros o los sitios web que enlazan con el sitio. Al salir del Sitio, nuestros Términos ya no rigen. Debes revisar los términos y políticas aplicables, incluidas las políticas de privacidad y las prácticas de recolección de datos de cualquier Sitio de Terceros, y debes hacer cualquier investigación que consideres necesaria o apropiada antes de proceder con cualquier transacción con terceros.</p>

    <h4><strong>XI.2 Anuncios y Promociones de Terceros</strong></h4>
    <p>Moneo puede publicar anuncios y promociones de terceros en el Sitio. Sus relaciones comerciales o la correspondencia con, o la participación en promociones de otros anunciantes que Moneo, y cualquier Términos, condiciones, garantías o representaciones asociadas a tales transacciones, son exclusivamente entre tú y dicho tercero. Moneo no es responsable de ninguna pérdida o daño de cualquier tipo incurrido como resultado de tales transacciones o como resultado de la presencia de anunciantes de terceros en el Sitio.</p>

    <h4><strong>XII. Terminación/Modificación de Servicios y Ofertas</strong></h4>
    <p>Sin perjuicio de cualquier disposición de estos Términos, Moneo se reserva el derecho, sin previo aviso y a su sola discreción, sin ninguna notificación o responsabilidad hacia ti, de:</p>
    <ol>
        <li>Terminar tu permiso para usar el Sitio, o cualquier parte del mismo;</li>
        <li>Bloquear o impedir tu futuro acceso y uso de toda o parte del Sitio, de los Servicios y / o las Ofertas;</li>
        <li>Cambiar, suspender o interrumpir cualquier aspecto del Sitio, los Servicios y / o las Ofertas;</li>
        <li>Imponer límites al Sitio, a los Servicios o a las Ofertas.</li>
    </ol>

    <h4><strong>XIII. Terminación de los Términos</strong></h4>
    <p>Moneo puede terminar estos Términos y tu uso del Sitio en cualquier momento. Tú puedes terminar el uso del Sitio en cualquier momento. Cuando tu cuenta Moneo se desactiva, no tienes derecho de usar el Sitio ni los Servicios. Podrás solicitar la dispersión de las Recompensas disponibles en tu cuenta Moneo hasta la fecha de terminación.</p>

    <h4><strong>XIV. Propiedad Intelectual</strong></h4>
    <p>XIV.1. Moneo cuenta con la titularidad y propiedad exclusiva para todas las marcas, avisos comerciales y programas de computación, mediante los cuales comercializa y presta sus servicios, incluyendo pero sin limitar: (i) la propiedad industrial; (ii) los derechos de autor respecto de las aplicaciones desarrolladas, programa(s) de computación, sistema(s) y/o sus derivados, otorgados por las autoridades competentes tanto en México, como en el resto del mundo; y (iii) todos los desarrollos, know how, signos distintivos y/o bienes o derechos que le correspondan en el ámbito de la propiedad intelectual por derecho propio; todo lo anterior identificado en lo sucesivo como "Propiedad Intelectual".</p>
    <p>XIV.2 Moneo no concede ninguna licencia o autorización de uso de ninguna clase sobre la Propiedad Intelectual que le concierne, salvo acuerdo expreso por escrito, por lo que el Usuario reconoce que: (i) no tiene derecho alguno sobre la Propiedad Intelectual, por lo que se obliga a respetar en todo momento los derechos de Moneo sobre ésta; (ii) no podrá modificar, alterar, suprimir, copiar o reproducir ya sea total o parcialmente, incluyendo pero sin limitar, el contenido informativo generado por Moneo, la Propiedad Intelectual y/o cualquier indicación contenida en el Sitio.</p>
    <p>XIV.3 Moneo podrá incluir dentro de sus Servicios, los servicios de terceros que pueden o no implicar un costo adicional para el Usuario. El Usuario deberá respetar los servicios de terceros que sean puestos a su disposición por Moneo y de los cuales Moneo cuenta con los derechos de explotación necesarios. Del mismo modo y cuando el Usuario acceda a los servicios de terceros a través de Moneo deberá respetar en todo momento los términos y condiciones que dichos terceros tengan dentro de los servicios que proporcionen.</p>
    <p>XIV.4 El Usuario, en virtud de los presentes Términos, no adquiere absolutamente ningún derecho o licencia sobre los Servicios prestados, sobre los programas de computadora necesarios para la prestación de los Servicios ni tampoco sobre la información técnica de seguimiento de los Servicios, a excepción de los derechos y licencias necesarios para el cumplimiento con los presentes Términos y únicamente para la duración temporal de los Servicios contratados.</p>

    <h4><strong>XV. Limitación; Indemnización</strong></h4>
    <p>Renuncias a cualquier reclamación o alegación de cualquier naturaleza contra Moneo, sus afiliados o subsidiarias, sus patrocinadores, contratistas, anunciantes, vendedores u otros socios, cualquiera de sus sucesores o concesionarios, o cualquiera de sus respectivos funcionarios, directores, agentes o empleados (colectivamente, las "Partes Exoneradas") que surjan de, o en cualquier forma, relacionadas con su uso del Sitio o los Servicios, incluyendo, sin limitación, las reclamaciones o alegaciones relacionadas con la presunta violación de derechos de propiedad , presunta inexactitud del contenido o alegaciones de que cualquier Parte Exonerada tiene o debería indemnizar, defender o eximir de responsabilidad a ti o a cualquier tercero de cualquier reclamación o alegación que surja de su uso u otra explotación del Sitio. Tú utilizas el Sitio y los Servicios bajo tu propio riesgo.</p>
    <p>Sin ninguna limitación de lo anterior, ni Moneo ni ninguna otra Parte Exonerada será responsable de ningún daño directo, especial, indirecto, ejemplar, consecuente, punitivo o cualquier otro daño de cualquier tipo, incluyendo pero no limitado a pérdida de uso, pérdida de ganancias, pérdidas de datos, pérdida de buena voluntad, datos u otras pérdidas intangibles u otras pérdidas económicas, ya sea en una acción contractual, extracontractual (incluyendo pero no limitado a negligencia) o de otro modo, que surja o esté relacionada de algún modo con el uso del Sitio y los Servicios incluyendo, sin limitación, los daños causados o resultantes de: (i) su uso o incapacidad para usar el Sitio o cualquier Servicio; (ii) cualquier tercero alega que tu uso del Sitio o cualquiera de los Servicios viola cualquier derecho de propiedad intelectual o derecho de privacidad de terceros; (iii) cualquier falla en el funcionamiento del Sitio o de los Servicios ya sea relacionada con errores, omisiones, interrupciones, defectos, demoras en la operación o transmisión, eliminación de archivos o correo electrónico, virus informáticos o cualquier fallo de rendimiento resultado de fallas en las comunicaciones, robo, destrucción o acceso no autorizado a Moneo o a cualquier otro registro, programa o servicio de la Parte Exonerada; (iv) cualquier daño que surja de comunicaciones o transacciones con otros Usuarios del Sitio, ya sea a través de blogs, los Servicios o de otra manera, o las personas que tú conozcas a través del Sitio; O (v) cualquier otro asunto relacionado con este Sitio o los Servicios, con base en contrato, negligencia, responsabilidad objetiva, incumplimiento fundamental, falta de propósito esencial o de otro tipo, si Moneo tenía algún conocimiento, real o constructivo, que podría incurrir en tales daños.</p>
    <p>En ningún caso, la responsabilidad global de Moneo, ya sea en contrato, garantía, agravio (incluyendo negligencia, ya sea activa, pasiva o imputada), responsabilidad por el producto, responsabilidad estricta u otra teoría, que surja o se relacione con el uso del Sitio excederá de cien pesos mexicanos.</p>
    <p>Deberás defender, indemnizar y eximir a Moneo y a las demás Partes Exoneradas de cualquier pérdida, daños, responsabilidades, costos, gastos, reclamaciones y procedimientos derivados de su uso del Sitio y del uso del Sitio por cualquier persona a quien das acceso a tu cuenta, incluyendo cualquier reclamo hecho por cualquier persona que cualquiera de tu Contenido de Usuario infrinja los derechos, incluyendo los derechos de propiedad intelectual, de cualquier tercero.</p>

    <h4><strong>XVI. Ley Aplicable</strong></h4>
    <p>Tú y Moneo acuerdan explícitamente que todas las disputas, reclamos u otros asuntos que surjan de o relacionados con el uso del Sitio serán regidos por las leyes de la Ciudad de México, Estados Unidos Mexicanos. Asimismo, se someten expresamente a la jurisdicción y competencia de los tribunales de la Ciudad de México, renunciando expresamente a cualquier otro fuero o jurisdicción que pudiera corresponderles en razón de sus domicilios presentes o futuros o por cualquier otra causa.</p>
    <p>Salvo en los casos en que la ley aplicable lo prohíba, cualquier reclamación, disputa o controversia (ya sea contractual o extracontractual, de conformidad con un estatuto o reglamento, o de otro modo, ya sea preexistente, presente o futuro) que surja o se relacione con:</p>
    <ol>
        <li>Estos Términos;</li>
        <li>El Sitio, los Servicios;</li>
        <li>Declaraciones orales o escritas, anuncios o promociones relacionados con estos Términos o con el Sitio; o</li>
        <li>Las relaciones que resulten de estos Términos, del Sitio o de los Servicios (colectivamente, una "Reclamación") serán referidas y determinadas por un árbitro único (con exclusión de los tribunales).</li>
    </ol>
    <p>Excepto donde lo prohíba la ley aplicable, tú renuncias a cualquier derecho que pueda tener para iniciar o participar en cualquier acción colectiva contra Moneo relacionada con cualquier Reclamación y, en su caso, también aceptas cancelar cualquier procedimiento colectivo contra Moneo. Si tienes una Reclamación, debes dar aviso por escrito a arbitrar en la dirección que se especifica en el primer párrafo de estos Términos. Si tenemos una Reclamación, te notificaremos para arbitrar en tu dirección proporcionada en tus Datos de Registro.</p>

    <h4><strong>XVII. Renuncia a la Garantía</strong></h4>
    <p>El Sitio y los Servicios disponibles en el Sitio se te proporcionan "tal como están", "como disponibles" sin representaciones, garantías, condiciones o garantías de Moneo de ningún tipo, expresas o implícitas. Moneo renuncia expresamente a todas las declaraciones, garantías, condiciones o garantías, expresas o implícitas, incluyendo, sin limitación, garantías implícitas de comerciabilidad, idoneidad para un propósito particular, título y no infracción. Moneo no declara ni garantiza que el Sitio o los Servicios sean exactos, completos, confiables, actuales o libres de errores, y renuncia expresamente a cualquier representación o garantía sobre la exactitud o el carácter propietario del Sitio, los Servicios o cualquier parte en esto. Tú eres el único responsable de cualquier daño resultante a tu sistema informático o pérdida de datos derivados del uso del Sitio o los Servicios.</p>
    <p>Mientras que Moneo intenta hacer su acceso y uso del Sitio seguro, Moneo no representa ni garantiza que el Sitio o cualquier Servicio estén libres de virus u otros componentes dañinos como resultado de ataques maliciosos de terceros.</p>

    <h4><strong>XVIII. Otros temas</strong></h4>
    <p>Si alguna cláusula de estos Términos se considera ilegal, nula o por cualquier motivo inaplicable, entonces dicha disposición se considerará separable de estos Términos y no afectará la validez y aplicabilidad de las disposiciones restantes. Estos Términos constituyen el acuerdo completo entre tú y Moneo con respecto al uso del Sitio, y reemplazan todas las comunicaciones anteriores o contemporáneas, ya sean electrónicas, orales o escritas entre tú y Moneo con respecto al uso del Sitio. Los encabezados utilizados en estos Términos se incluyen sólo por conveniencia y no tienen ningún efecto legal o contractual y no afectarán la construcción o interpretación de estos Términos. Las partes confirman que desean que estos Términos, así como cualquier otro documento relacionado con los presentes Términos, incluidos los avisos, hayan sido y estén redactados únicamente en el idioma español.</p>

    <h4><strong>XIX. Cancelación del servicio</strong></h4>
    <p>Para cancelar tu cuenta, favor de enviar un correo a soporte&#64;moneo.mx solicitando dicha cancelación, misma que se procesará de manera inmediata. Es importante que el correo que usas para escribirnos sea el mismo correo que usaste para registrarte.</p>
    <p>El equipo de atención a clientes de Moneo enviará un correo electrónico como comprobante de la cancelación.</p>

    <h4><strong>XX. Revocación del consentimiento</strong></h4>
    <p>En caso de que el Usuario quisiera revocar su consentimiento de aceptación del Servicio, el mismo podrá hacerlo sin responsabilidad ni justificación alguna dentro de los cinco días hábiles contados a partir de la aceptación del Servicio, siempre que el mismo no haya sido utilizado por el Usuario.</p>
    <p>Por lo cual, se procederá de manera inmediata a la cancelación del Servicio, conforme al mecanismo descrito en la sección XIX anterior.</p>

    <h4><strong>XXI. Disponibilidad del Sitio y/o de los Servicios</strong></h4>
    <p>Moneo no garantiza la disponibilidad y continuidad de la operación del Sitio o de los Servicios, ni la utilidad y/o rendimiento de los Servicios en relación con ninguna actividad específica. El Usuario libera a Moneo de cualquier responsabilidad que, por cualquier daño, perjuicio o pérdida de cualquier naturaleza, pudiera surgir debido a la falta de disponibilidad o continuidad de operación de Moneo, del Sitio o de los Servicios.</p>

    <h4><strong>XXII. Mecanismos de notificaciones o medios de comunicación con el Usuario</strong></h4>
    <p>Los usuarios reconocen y aceptan que las notificaciones, avisos y acuerdos de cualquier tipo, que debieran hacerse por virtud de la ley, del Sitio, de Moneo o de los Servicios, serán hechos por medio de su cuenta en el Sitio con copia al correo electrónico proporcionado por el Usuario, mismas que surtirán sus efectos al momento de su publicación.</p>

    <h4><strong>XXIII. Mecanismos de seguridad del Sitio</strong></h4>
    <p>El sistema cuenta con encriptación de datos por medio de SSL (Secure socket layer) y un certificado emitido por: Let's Encrypt Authority X3.</p>
    <p>Nuestros servidores se encuentran en una locación privado con medidas de seguridad dentro de la instalaciones de Digiplex Noruega y cuenta con lo siguientes certificados:</p>
    <ol>
        <li type="disc">ISO 9001: 2015 Quality Management Systems (Sistemas de Gestión de Calidad)</li>
        <li type="disc">ISO 27001: 2013 Information Security Management System Standard (Estándar de Seguridad de Sistemas de Información)</li>
        <li type="disc">ISO 14001: 2015 Environmental Management System Standard (Estándard de Ambiente)</li>
        <li type="disc">ISO 45001: 2018 Occupational Health and Safety Management (Condiciones Seguras de Trabajo)</li>
    </ol>

    <h4><strong>XXIV. Compras relacionadas con medicamentos o fármacos</strong></h4>
    <p>En relación con las operaciones realizadas con farmacias o compra de medicamentos, le pedimos al Usuario de manera atenta eliminar o marcar en negro los nombres o cualquier dato relacionado con medicamentos o productos que pudieran revelar de alguna manera el estado de salud  presente o futuro del Usuario.</p>

    <h4><strong>XXV. Preguntas, dudas, aclaraciones o comentarios</strong></h4>
    <p>Si tienes alguna pregunta, duda o solicitud de aclaración relacionada a los Términos o al uso del Sitio, por favor contáctanos en soporte&#64;moneo.mx.</p>
    <p>Horario de atención: 9am - 6pm, lunes a viernes</p>
    <p>Datos de contacto</p>
    <p>soporte&#64;moneo.mx.</p>
    <p><a href="https://www.moneo.cash/" target="_blank">https://www.moneo.cash</a></p>
    <hr/>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" cdkFocusInitial>{{ 'OK' | translate }}</button>
</div>
